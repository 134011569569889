export default {
  methods: {
    fallbackCopyTextToClipboard(text) {
      let textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        return document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy', err);
        return false;
      }
      finally {
        document.body.removeChild(textArea);
      }
    },
    copyToClipboard(text) {
      if (!navigator.clipboard) {
        return new Promise((resolve) => {
          resolve(this.fallbackCopyTextToClipboard(text));
        });
      }

      return navigator.clipboard.writeText(text).then(function() {
        return true;
      }, function(err) {
        console.error('Could not copy text: ', err);
        return false;
      });
    }
  }
}
